import { doc, setDoc, getDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";

export const getDoubtsFirebase = async () => {
  const docRef = doc(db, "lists", "doubtsPor");
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    console.log(`dobts list loaded`);
    return docSnap.data();
  } else {
    console.log("No appointments document");
  }
};

export const getPostBySlugFirebase = async (slug) => {
  const docRef = doc(db, "doubtsPor", slug);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    console.log(`post ${slug} loaded`);
    return docSnap.data();
  } else {
    console.log("No such document");
  }
};

export const createPostFirebase = async (newPost, doubtsPor) => {
  await setDoc(doc(db, "doubtsPor", newPost.slug), {
    slug: newPost.slug,
    title: newPost.title,
    content: newPost.content,
  });

  await setDoc(doc(db, "lists", "doubtsPor"), {
    doubtsPor,
  });
  console.log("post created");
};
