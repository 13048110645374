import React from "react";
import { useNavigate } from "react-router-dom";

import { useForm } from "../../Hooks/useForm";
import { Button } from "../Forms/Button";
import { Input } from "../Forms/Input";
import { UserContext } from "../../Context/UserContext";
import { Head } from "../../Utils/Head";

const LoginPasswordLost = () => {
  const email = useForm("email");
  const { passwordReset } = React.useContext(UserContext);
  const navigate = useNavigate();

  async function handleSubmitLogin(event) {
    event.preventDefault();

    if (email.validate()) {
      passwordReset(email.value);
      navigate("/");
    }
  }
  return (
    <section>
      <Head title="Recuperar Senha" />
      <h1 className="title">Recuperar Senha</h1>
      <form action="" onSubmit={handleSubmitLogin}>
        <Input label="Email" type="email" name="email" {...email} />
        <Button>Enviar</Button>
      </form>
    </section>
  );
};

export default LoginPasswordLost;
