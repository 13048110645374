import React from "react";
import { Link } from "react-router-dom";

import css from "./Login.module.css";
import { UserContext } from "../../Context/UserContext";
import { useForm } from "../../Hooks/useForm";
import { Button } from "../Forms/Button";
import { Input } from "../Forms/Input";
import { Error } from "../../Utils/Error";
import { Head } from "../../Utils/Head";

export const LoginForm = () => {
  const email = useForm("email");
  const password = useForm();
  const { userLogin, loading, error } = React.useContext(UserContext);

  async function handleSubmitLogin(event) {
    event.preventDefault();

    if (email.validate() && password.validate()) {
      userLogin(email.value, password.value);
    }
  }

  return (
    <section>
      <Head title="Login" />
      <h1 className="title">Sign In</h1>
      <form onSubmit={handleSubmitLogin}>
        <Input label="Email" type="email" name="email" {...email} />
        <Input label="Password" type="password" name="password" {...password} />
        <Link className="textLink" to="/login/recover">
          Esqueceu a senha?
        </Link>
        <div className={css.loginForm}>
          {loading ? (
            <Button disabled>Loading...</Button>
          ) : (
            <Button>Submit</Button>
          )}
          <Error error={error} />
        </div>
      </form>
    </section>
  );
};
