import React, { useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";

import css from "./header.module.css";
import { UserContext } from "../Context/UserContext";
import { ReactComponent as Logo } from "../assets/mairini-logo.svg";
import OutsideAlerter from "../Utils/OutsideAlerter";

export const Header = () => {
  const { userLogout } = useContext(UserContext);
  const [menuButton, setMenuButton] = useState(false);
  const { pathname } = useLocation();

  return (
    <div className={css.topbar}>
      <div className={css.topbarWrapper}>
        <div className={css.topLeft}>
          <div className={css.logo}>
            <Link className={css.logo} to="/" aria-label="Mairini - Home">
              <Logo />
              Vortari
            </Link>
          </div>
          <div className={css.tollbar}>
            <Link
              className={
                pathname === "/" || pathname === "/users"
                  ? css.tollbarItensActive
                  : css.tollbarItens
              }
              to="/"
            >
              Português
            </Link>
            {/* <Link
              className={
                pathname === "/schedule"
                  ? css.tollbarItensActive
                  : css.tollbarItens
              }
              to="/schedule"
            >
              Gramática
            </Link>
            <Link
              className={
                pathname === "/schedule"
                  ? css.tollbarItensActive
                  : css.tollbarItens
              }
              to="/schedule"
            >
              Literatura
            </Link> */}
          </div>
        </div>
        <div className={css.topRight}>
          <div className={css.avatar}>
            <div className={css.menuContainer}>
              <OutsideAlerter setMenuButton={setMenuButton}>
                <button
                  aria-label="Menu"
                  className={css.userButton}
                  onClick={() => setMenuButton(!menuButton)}
                ></button>
                {menuButton && (
                  <nav className={css.navUserButton}>
                    <ul>
                      <Link to="/account">
                        <li>
                          <button onClick={() => setMenuButton(!menuButton)}>
                            Accont
                          </button>
                        </li>
                      </Link>
                      <li>
                        <button onClick={userLogout}>Logout</button>
                      </li>
                    </ul>
                  </nav>
                )}
              </OutsideAlerter>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
