import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import HTMLReactParser from "html-react-parser";

import css from "./EditPost.module.css";
import { PostContext } from "../Context/PostContext";
import { Head } from "../Utils/Head";
import { Header } from "../Layout/Header";
import {
  createPostFirebase,
  getPostBySlugFirebase,
} from "../Utils/firebasePosts";
import { Input } from "./Forms/Input";
import { InputArea } from "./Forms/InputArea";

export const EditPost = () => {
  const { doubtsList, setDoubtsList } = useContext(PostContext);
  const params = useParams();
  const [post, setPost] = useState("");
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [content, setContent] = useState("");

  useEffect(() => {
    getPostBySlugFirebase(params.slug).then(function (result) {
      if (result) {
        setPost(result);
        setTitle(result.title);
        setContent(result.content);
        setSlug(result.slug);
      } else {
        setPost("");
      }
    });
  }, [params.slug]);

  const slugify = (text) =>
    text
      .toString()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .trim()
      .replace(/\s+/g, "-")
      .replace(/[^\w-]+/g, "")
      .replace(/--+/g, "-");

  function updatePost() {
    const updatedPost = {
      slug: slug,
      title: title,
      content: content,
    };

    const updatedPostSnippet = {
      slug: slug,
      title: title,
    };

    let updatedList = [...doubtsList];

    if (updatedList.map((item) => item.slug).indexOf(slug) < 0) {
      updatedList.push(updatedPostSnippet);
    } else {
      updatedList = updatedList.filter((d) =>
        d.slug === post.slug ? updatedPostSnippet : d
      );
    }
    createPostFirebase(updatedPost, updatedList);
    setDoubtsList([...updatedList]);
    alert("post updated");
  }

  return (
    <>
      <Head title="Edit" description="Edit - Vortari" />
      <Header />
      <div className={css.container}>
        <h1>Edit Post</h1>
        <Input
          type="text"
          name="title"
          placeholder="Title"
          label="Title"
          value={title}
          required
          className={css.inputText}
          onChange={(e) => {
            setTitle(e.target.value);
            setSlug(slugify(e.target.value));
          }}
          autoComplete="off"
        />
        <Input
          type="text"
          name="slug"
          placeholder="Slug"
          label="Slug"
          value={slug}
          required
          className={css.inputText}
          onChange={(e) => setSlug(e.target.value)}
          autoComplete="off"
        />
        <InputArea
          type="text"
          name="content"
          placeholder="Content"
          label="Edit code"
          value={content}
          required
          className={css.inputText}
          onChange={(e) => setContent(e.target.value)}
          autoComplete="off"
        />
        <button className="saveButton" onClick={() => updatePost()}>
          save
        </button>
        <h4>Preview</h4>
        <div className={css.preview}>{HTMLReactParser(content)}</div>
      </div>
    </>
  );
};
