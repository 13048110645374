import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { sendEmailVerification } from "firebase/auth";

import { auth } from "../firebaseConfig";
import { UserContext } from "../Context/UserContext";

export const RequireAuth = () => {
  const { user, loading } = React.useContext(UserContext);
  const [emailSended, setEmailSended] = React.useState(false);

  let location = useLocation();
  if (loading === false) {
    if (!user) {
      return <Navigate to="/login" state={{ from: location }} />;
    }
    if (user && user.emailVerified === false) {
      return (
        <div>
          <h4>Email not verified</h4>
          <button
            className="defaultButton"
            onClick={() => {
              setEmailSended(true);
              sendEmailVerification(auth.currentUser).then(() => {
                console.log("Email verification sent!");
                // ...
              });
            }}
          >
            click here to send a verification email
          </button>
          {emailSended && <div>email sended</div>}
        </div>
      );
    }
    return <Outlet />;
  } else return <div>Loading...</div>;
};
