import React from "react";
import { Routes, Route } from "react-router-dom";

import "./App.css";
import "./theme.css";
import { UserStorage } from "./Context/UserContext";
import { Home } from "./Components/Home";
import { Login } from "./Components/Login/Login";
import { RequireAuth } from "./Utils/RequireAuth";
import { NewPost } from "./Components/NewPost";
import { EditPost } from "./Components/EditPost";
import { PostStorage } from "./Context/PostContext";

function App() {
  return (
    <UserStorage>
      <PostStorage>
        <Routes>
          <Route path="login/*" element={<Login />} />
          <Route element={<RequireAuth />}>
            <Route path="/" element={<Home />} />
            <Route path="/new" element={<NewPost />} />
            <Route path="/edit/:slug" element={<EditPost />} />
          </Route>
        </Routes>
      </PostStorage>
    </UserStorage>
  );
}

export default App;
