import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import css from "./Home.module.css";
import { UserContext } from "../Context/UserContext";
import { PostContext } from "../Context/PostContext";
import { Head } from "../Utils/Head";
import { Header } from "../Layout/Header";
import { deleteDoc, doc, setDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";

export const Home = () => {
  const { user } = useContext(UserContext);
  const { doubtsList, setDoubtsList } = useContext(PostContext);
  const navigate = useNavigate();

  const deletePostFirebase = async (slug) => {
    let doubtsPor = doubtsList.filter((doubt) => slug !== doubt.slug);
    await deleteDoc(doc(db, "doubtsPor", slug));
    await setDoc(doc(db, "lists", "doubtsPor"), {
      doubtsPor,
    });
    setDoubtsList([...doubtsPor]);
    console.log(`post ${slug} deleted`);
  };

  return (
    <div>
      <Head title="Admin" description="Admin - Vortari" />
      <Header />
      <div className={css.container}>
        <h1>{user.displayName}</h1>
        <button className="defaultButton" onClick={() => navigate("/new")}>
          New Post
        </button>

        {doubtsList &&
          doubtsList.map((doubt, i) => (
            <div key={i} className={css.post}>
              <p>{doubt.title}</p>
              <button
                className="defaultButton"
                onClick={() => navigate(`/edit/${doubt.slug}`)}
              >
                Edit
              </button>
              <button
                className="defaultButton"
                onClick={() => deletePostFirebase(doubt.slug)}
              >
                Delete
              </button>
            </div>
          ))}
      </div>
    </div>
  );
};
